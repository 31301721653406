

<script>
import iframeMixin from '../iframeMixin.vue'

export default {
  mixins: [iframeMixin],
  computed: {
    url() {
      // return '';
       return '/ureport/preview?_u=mysql:matchOrderList.ureport.xml'
    },
  },
}
</script>
